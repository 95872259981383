<template>
    <div class="view-page customer-maintenance">
        <div class="page-header">
            <h1>
                社員マスタ
                <button
                    class="addButton"
                    @click="showEditor()"
                    v-if="hasTicket('emp-c')"
                >
                    ＋
                </button>
            </h1>
            <OpSearchTextField
                v-model.trim="query.words"
                maxlength="80"
                @changed-query="search"
            />
        </div>
        <div>
            <table style="margin:0 auto;">
                <tbody
                    v-for="(data, ix) in payload_data"
                    :key="ix"
                    @click="showView(data)"
                >
                    <tr>
                        <td>
                            <div class="title">
                                社員ID
                            </div>
                            <div class="value">
                                {{ data.employee_id }}
                            </div>
                        </td>
                        <td>
                            <div class="title">
                                社員名
                            </div>
                            <div class="value">
                                {{ data.employee_name_long }}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <OpModal
            :title="viewDialogTitle"
            v-if="showViewDialog"
            :alterButton="hasTicket('emp-u')"
            :removeButton="hasTicket('emp-d')"
            v-slot="{ close }"
            @close="showViewDialog = false"
            @clickAlter="showAlterEditor"
            @clickRemove="showDeleteConfirm = true"
        >
            <EmployeeView
                name="employeeView"
                v-model="selectedData"
                @fin="close"
                @loadedData="loadedSelectedData"
            />
        </OpModal>
        <OpModal
            :title="editDialogTitle"
            v-if="showEditDialog"
            @close="showEditDialog = false"
            v-slot="{ close, step }"
            :steps="['入力', '確認', '完了']"
        >
            <EmployeeEditor
                v-model="selectedData"
                name="employeeEditor"
                :mode="editMode"
                @fin="close"
                @step="step"
                @updated-data="updatedData"
                @created-data="createdData"
                @moveto="showView"
            />
        </OpModal>

        <OpModal
            v-if="showDeleteConfirm"
            title="部署の削除"
            @close="showDeleteConfirm = false"
            v-slot="{ close, step }"
            :steps="['確認', '完了']"
        >
            <OpConfirm
                confirm="削除してもよろしいですか？"
                complete="削除しました。"
                :okfunction="deleteEmployee"
                @fin="close"
                @step="step"
            ></OpConfirm>
        </OpModal>
    </div>
</template>

<script>
import EmployeeView from "@/views/employee/EmployeeView.vue";
import EmployeeEditor from "@/views/employee/EmployeeEditor.vue";
import OpSearchTextField from "@/components/OpSearchTextField.vue";
import OpConfirm from "@/components/OpConfirm.vue";
// const axios = require("axios");

export default {
    name: "EmployeeList",
    components: {
        EmployeeView,
        EmployeeEditor,
        OpSearchTextField,
        OpConfirm
    },
    data: () => ({
        showViewDialog: false,
        viewDialogTitle: "",
        selectedData: null,
        showEditDialog: false,
        query: {},
        editMode: "",
        showDeleteConfirm: false,
        payload_data: []
    }),
    methods: {
        showEditor: function(data) {
            this.editMode = "create";
            this.selectedData = data;
            this.editDialogTitle = "社員登録";
            this.showEditDialog = true;
        },
        showAlterEditor: function() {
            let data = this.selectedData;
            this.editMode = "update";
            this.editDialogTitle =
                "社員情報の修正  ( ID : " + data.employee_id + " )";
            this.showEditDialog = true;
        },

        showView: function(data) {
            this.selectedData = data;
            this.viewDialogTitle = "社員 ( ID : " + data.employee_id + " )";
            this.showViewDialog = true;
        },
        search: function(words) {
            let params = null;
            if (!words) {
                words = this.query.words;
            }
            if (words) {
                params = { words: words };
            }
            this.readDataToArray(
                this.makeurl("emp", params),
                list => (this.payload_data = list)
            );
        },
        loadedSelectedData: function(data) {
            this.selectedData = data;
        },
        updatedData: function(value) {
            this.selectedData = value;
            this.search();
        },
        createdData: function() {
            this.search();
        },
        deleteEmployee: function(func) {
            let d = this.selectedData;
            let data = this.convertToRequestData(d);
            let url = this.makeurl("emp", data.employee_id);
            this.deleteData(
                url,
                data,
                () => {
                    func();
                    this.showViewDialog = false;
                    this.selectedData = null;
                    this.search();
                },
                () => {
                    this.showDeleteConfirm = false;
                }
            );
        }
    },
    created: function() {
        this.search();
    }
};
</script>

<style scoped>
.view-header {
    display: grid;
    grid-template-columns: 200px auto 1fr;
    grid-template-rows: auto;
    align-items: baseline;
    grid-column-gap: 20px;

    position: sticky;
    top: 0;
    padding: 5px 100px 5px 100px;
}
thead {
    display: none;
}
td {
    vertical-align: top;
    border-right-style: none;
    border-left-style: none;
}
td .title {
    font-size: 0.5em;
    display: block;
}
td .value {
    padding: 3px 0;
}
td .value .id {
    font-size: 0.75em;
    display: inline-block;
}
td .value .id:after {
    content: " : ";
}
td .item {
    display: inline-block;
    vertical-align: top;
    padding: 2px 10px;
}
tbody {
    cursor: pointer;
}
tbody:nth-child(2n + 1) {
    background: var(--odd-bg-color);
}
tbody tr td {
    border-style: none;
}
tbody tr:last-child td {
    border-bottom: 1px solid var(--border-color);
}
</style>
