<template>
    <div class="view-page">
        <div class="content">
            <div class="item">
                <div class="title">社員ID</div>
                <span class="value">{{ d.employee_id }}</span>
            </div>
            <div class="item">
                <div class="title">社員（正式）</div>
                <span class="value">{{ d.employee_name_long }}</span>
            </div>
            <div class="sysinfo">
                <div class="item">
                    <div class="title">作成</div>
                    <span class="value">{{ d.create_timestamp }}</span>
                    <span class="value">{{ d.create_account_id }}</span>
                </div>
                <div class="item">
                    <div class="title">変更</div>
                    <span class="value">{{ d.update_timestamp }}</span>
                    <span class="value">{{ d.update_account_id }}</span>
                </div>
                <div class="item">
                    <div class="title">version</div>
                    <span class="value">{{ d.row_version }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "EmployeeView",
    model: {
        prop: "model"
    },
    props: {
        model: Object
    },

    data: function() {
        return {
            d: this.model
        };
    }
};
</script>

<style></style>
