<template>
    <div>
        <div class="form" v-if="isStep(0)">
            <div class="emp-form">
                <div class="item emp-id">
                    <OpTextField
                        label="社員ID"
                        v-model.trim="d.employee_id"
                        required
                        name="employee_id"
                        ref="employee_id"
                        pattern="A9"
                        fixlength="6"
                        lpad="0"
                        :readonly="isUpdate()"
                        @changed-valid="changedValid"
                    />
                </div>
                <div class="item emp-name-long">
                    <OpTextField
                        label="社員名（正式）"
                        v-model.trim="d.employee_name_long"
                        maxlength="20"
                        required
                        name="employee_name_long"
                        @changed-valid="changedValid"
                        ref="employee_name"
                    />
                </div>
            </div>
            <div class="control-box">
                <button @click="doCancel">キャンセル</button>
                <button @click="setStep(1)" v-bind:disabled="hasErrors">
                    確認
                </button>
            </div>
        </div>
        <div v-if="isStep(1)">
            <div class="content emp-confirm">
                <div class="item emp-id">
                    <div class="title">社員ID</div>
                    <span class="value">{{ d.employee_id }}</span>
                </div>
                <div class="item emp-name-long">
                    <div class="title">社員名（正式）</div>
                    <span class="value">{{ d.employee_name_long }}</span>
                </div>
            </div>
            <div class="control-box">
                <button class="control-button" @click="setStep(0)">
                    戻る
                </button>
                <button
                    class="control-button"
                    v-if="isUpdate()"
                    @click="updateData"
                    :disabled="requesting"
                >
                    登録
                </button>
                <button
                    class="control-button"
                    v-else
                    @click="addData"
                    :disabled="requesting"
                >
                    登録
                </button>
            </div>
        </div>
        <div v-if="isStep(2)" class="complete">
            <div class="message">
                社員 (ID : {{ d.employee_id }} )
                <span v-if="isUpdate()">の情報を修正しました。</span>
                <span v-else>を登録しました</span>
            </div>

            <div class="control-box">
                <div></div>
                <div>
                    <button @click="close">
                        閉じる
                    </button>
                </div>
                <div>
                    <button v-if="isCreate()" @click="moveDetail">
                        詳細
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EditorMixin from "@/views/EditorMixin.vue";

export default {
    name: "EmployeeEditor",
    mixins: [EditorMixin],
    model: {
        prop: "model"
    },
    props: {
        model: {
            default: function() {
                return {};
            },
            type: Object
        },
        mode: String
    },
    data: function() {
        return {
            requesting: false,
            d: { ...this.model }
        };
    },
    methods: {
        isUpdate: function() {
            return this.mode == "update";
        },
        isCreate: function() {
            return this.mode == "create";
        },
        addData: function() {
            this.postData(this.makeurl("emp"), this.d, d => {
                this.d = d;
                this.setStep(2);
                this.$emit("created-data", this.d);
            });
        },
        updateData: function() {
            this.putData(this.makeurl("emp", this.d.employee_id), this.d, d => {
                this.d = d;
                this.setStep(2);
                this.$emit("updated-data", this.d);
            });
        },
        moveDetail: function() {
            this.$emit("moveto", this.d);
            this.close();
        }
    },
    mounted: function() {
        if (this.isCreate()) {
            this.$refs.employee_id.focus();
        } else {
            this.$refs.employee_name.focus();
        }
    }
};
</script>

<style scoped>
.control-box {
    display: grid;
    border-top: 1px solid var(--border-color);
    padding: 10px 0;
    grid-template-columns: 200px 1fr 200px;
    justify-items: center;
}

.emp-form {
    position: relative;
    display: grid;
    grid-template-areas: "emp-id" "emp-name-long";
    grid-row-gap: 10px;
    grid-column-gap: 30px;
}

.emp-confirm {
    position: relative;
    display: grid;
    grid-template-areas: "emp-id" "emp-name-long";
    grid-row-gap: 10px;
    grid-column-gap: 30px;
}

.emp-id {
    grid-area: emp-id;
}
.emp-name-long {
    grid-area: emp-name-long;
}
</style>
